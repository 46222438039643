import React from "react";
import cn from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../common/modal";
import modalStyles from "../../../../common/modal/modal.module.css";
import ModalControl from "../../../../modalControl";
import { getInitialsListing, renderBrandIcon } from "../../../../../helpers";
import { OverlayPreloader } from "../../../../common/preloader";
import styles from "./pause.module.css";

export const Pause = ({ closeModal, clearAllModals, itemToEdit, handlePause, preloadersPauseMembership }) => {
  const { t } = useTranslation();

  const {
    card_last4: last4,
    card_name: cardName,
    terms,
    listing_id: listingId,
    card_brand: brand,
    product_name: productName,
    expire_date: expireDate,
    attorney,
    _profession: listingType
  } = itemToEdit;
  const isFree = terms?.toLowerCase()?.includes("free");
  const listingActive = isFree ? styles.listingActive : "";

  const onExit = () => {
    closeModal();
  };

  const onConfirm = () => {
    handlePause(listingId, { location: { pathname: listingType?.toLowerCase() } });
  };

  return (
    <Modal closeModal={closeModal} classNameMain={styles.paymentOkMain} clearAllModals={clearAllModals}>
      {preloadersPauseMembership && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />}
      <section>
        <div className={modalStyles.pointer}>PAUSE LISTING</div>
        <div className={styles.popupHeading}>{t("dashboard_pause_modal_h1")}</div>
      </section>
      <div className={styles.description}>
        {isFree ? t("dashboard_pause_modal_description_free") : t("dashboard_pause_modal_description_sponsor")}
      </div>
      <p className={styles.deleteDescription}>{t("dashboard_pause_modal_listing_title")}</p>
      <div className={cn(styles.listing, listingActive)}>
        <div className={styles.listingInitials}>{getInitialsListing(itemToEdit)}</div>
        <div>
          <p className={styles.listingType}>{productName}</p>
          {attorney && <p className={styles.listingName}>{attorney}</p>}
        </div>
      </div>

      {!isFree && (
        <>
          <div className={styles.plan}>
            <div className={styles.planContent}>
              <p className={styles.planTitle}>{t("dashboard_pause_modal_payment_plan_title")}</p>
            </div>
            <p className={styles.planName}>{`${productName} - ${terms}`}</p>
            {expireDate && <p className={styles.planName}>{`Expires ${format(new Date(expireDate), "MM/dd/yyyy")}`}</p>}
          </div>

          {(last4 || cardName) && (
            <div className={styles.method}>
              <div className={styles.methodContent}>
                <p className={styles.methodTitle}>{t("dashboard_pause_modal_payment_method_title")}</p>
              </div>
              <div className={styles.card}>
                {brand && (
                  <div className={styles.cardImg}>
                    <img src={renderBrandIcon(brand)} alt={`${brand} card`} />
                  </div>
                )}

                <div>
                  {last4 && <span className={styles.cardNumber}>**** - **** - **** - {last4}</span>}
                  {cardName && <p className={styles.cardDefault}>{cardName}</p>}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <ModalControl onExit={onExit} onConfirm={onConfirm}>
        {t("dashboard_pause_modal_confirm_button")}
      </ModalControl>
    </Modal>
  );
};
